// extracted by mini-css-extract-plugin
export var Phase = "methodology-module--Phase--gO2yM";
export var Phase_circle = "methodology-module--Phase_circle--hxhk1";
export var Phase_description = "methodology-module--Phase_description--duopF";
export var Phase_icon = "methodology-module--Phase_icon--vSv6Z";
export var Phase_number = "methodology-module--Phase_number--rMLR1";
export var Phase_text = "methodology-module--Phase_text--vE5f2";
export var Phase_title = "methodology-module--Phase_title--FZFrq";
export var Phase_wrapper = "methodology-module--Phase_wrapper--ZXqWS";
export var Pilar = "methodology-module--Pilar--bxtw9";
export var Pilar_description = "methodology-module--Pilar_description--fofd6";
export var Pilar_icon = "methodology-module--Pilar_icon--D-0ka";
export var Pilar_title = "methodology-module--Pilar_title--ZJYVM";
export var Pilars = "methodology-module--Pilars--2DUdG";
export var Pilars_description = "methodology-module--Pilars_description--w4pDX";
export var Pilars_grid = "methodology-module--Pilars_grid--7KK0m";
export var Pilars_text = "methodology-module--Pilars_text--B1EI4";
export var Pilars_title = "methodology-module--Pilars_title--EDlP-";
export var Slider = "methodology-module--Slider--+D-i+";
export var Slider_wrapper = "methodology-module--Slider_wrapper--0mobP";
export var Teams = "methodology-module--Teams--zIGVk";
export var Teams_description = "methodology-module--Teams_description--vNHFc";
export var Teams_title = "methodology-module--Teams_title--Irg7c";
export var Teams_wrapper = "methodology-module--Teams_wrapper--dKyXp";
export var Tech = "methodology-module--Tech--XH0Z6";
export var TechLogo = "methodology-module--TechLogo--x+5sT";
export var TechLogo_img = "methodology-module--TechLogo_img--IgBoa";
export var TechLogos = "methodology-module--TechLogos--ueGym";
export var TechLogos_grid = "methodology-module--TechLogos_grid--ju0xe";
export var TechLogos_gridCol = "methodology-module--TechLogos_gridCol--2hESK";
export var TechLogos_wrapper = "methodology-module--TechLogos_wrapper--ICU70";
export var Tech_description = "methodology-module--Tech_description--gpmfF";
export var Tech_title = "methodology-module--Tech_title--K5eo6";
export var Workflow = "methodology-module--Workflow--eiUH2";
export var Workflow_title = "methodology-module--Workflow_title--EltkC";
export var Workflow_wrapper = "methodology-module--Workflow_wrapper--EXpIz";
export var l = "methodology-module--l--gb8U-";
export var m = "methodology-module--m--9v1ya";
export var s = "methodology-module--s--781J+";
export var xl = "methodology-module--xl--kAXvK";
export var xs = "methodology-module--xs--f13xw";