import * as React from "react";
import BoundedSection from "../BoundedSection/";
import * as style from "./awesomeQuote.module.scss";
import Parallax from "../Parallax";
import { GatsbyImage } from "gatsby-plugin-image";

const AwesomeQuote = ({ img, imgAlt = "", quote }) => {
  return (
    <BoundedSection className={style.AwesomeQuote}>
      <Parallax distance="0.08" className={style.AwesomeQuote_img}>
        <GatsbyImage image={img} alt={imgAlt} />
      </Parallax>
      <p className={style.AwesomeQuote_quote}>{quote}</p>
    </BoundedSection>
  );
};

export default AwesomeQuote;
