import * as React from "react";
import * as style from "./styles/methodology.module.scss";
import { graphql } from "gatsby";
import uuid from "react-uuid";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import Masonry from "react-masonry-css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { useMediaQuery } from "react-responsive";
import {
  mobileBreakpoint,
  DesktopView,
  NotDesktopView,
} from "components/Mediaqueries";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection";
import BoundedSection from "components/BoundedSection/";
import Parallax from "components/Parallax";
import MorphingScrollImg from "components/MorphingScrollImg";
import AwesomeQuote from "components/AwesomeQuote";
import Cta from "components/Cta";

import JavaLogo from "../images/tech_logos/java.svg";
import JavaScriptLogo from "../images/tech_logos/javascript.svg";
import ReactLogo from "../images/tech_logos/react.svg";
import HtmlLogo from "../images/tech_logos/html.svg";
import CssLogo from "../images/tech_logos/css.svg";
import RailsLogo from "../images/tech_logos/rails.svg";
import DockerLogo from "../images/tech_logos/docker.svg";
import RestLogo from "../images/tech_logos/rest.svg";
import GraphQlLogo from "../images/tech_logos/graphql.svg";
import GRPCLogo from "../images/tech_logos/grpc.svg";
import KafkaLogo from "../images/tech_logos/kafka.svg";
import CucumberLogo from "../images/tech_logos/cucumber.svg";
import CypressLogo from "../images/tech_logos/cypress.svg";
import KubernetesLogo from "../images/tech_logos/kubernetes.svg";
import MongoDbLogo from "../images/tech_logos/mongodb.svg";
import MySqlLogo from "../images/tech_logos/mysql.svg";
import PostgreSQlLogo from "../images/tech_logos/postgresql.svg";
import SendTimeExtensionTwoToneIcon from "@mui/icons-material/SendTimeExtensionTwoTone";
import TerminalTwoToneIcon from "@mui/icons-material/TerminalTwoTone";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import RocketLaunchTwoToneIcon from "@mui/icons-material/RocketLaunchTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import RocketIconTwoTone from "@mui/icons-material/RocketTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone";

const slides = [
  {
    img: (data) => data && getImage(data.slider1),
    alt: "",
  },
  {
    img: (data) => data && getImage(data.slider2),
    alt: "",
  },
];

const Slider = ({ data }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <BoundedSection
      className={style.Slider}
      boundedClassName={style.Slider_wrapper}
      data-cursor-size="120px"
      data-cursor-color="#081832"
    >
      <Swiper
        enabled={isMobile}
        slidesPerView={isMobile ? 1.25 : 2}
        spaceBetween={isMobile ? 16 : 32}
        slidesPerGroup={isMobile ? 1 : 2}
        loop={true}
        loopFillGroupWithBlank={true}
        className="mySwiper"
      >
        {slides.map(({ img, alt }) => (
          <SwiperSlide key={uuid()}>
            <MorphingScrollImg img={img(data)} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </BoundedSection>
  );
};

const Pilar = ({ icon, title, description }) => (
  <div className={style.Pilar}>
    <div className={style.Pilar_icon}>{icon}</div>
    <h4 className={style.Pilar_title}>{title}</h4>
    <p className={style.Pilar_description}>{description}</p>
  </div>
);

const Pilars = ({ t }) => (
  <BoundedSection className={style.Pilars}>
    <div className={style.Pilars_text}>
      <h3 className={style.Pilars_title}>
        <Trans i18nKey="methodology.pilars.title" />
      </h3>
      <div className={style.Pilars_description}>
        <p>{t("methodology.pilars.description")}</p>
      </div>
    </div>
    <div className={style.Pilars_grid}>
      <Pilar
        icon={<ForumTwoToneIcon />}
        title={t("methodology.pilar.adaptation.title")}
        description={t("methodology.pilar.adaptation.description")}
      />
      <Pilar
        icon={<SendTimeExtensionTwoToneIcon />}
        title={t("methodology.pilar.delivery.title")}
        description={t("methodology.pilar.delivery.description")}
      />
      <Pilar
        icon={<TerminalTwoToneIcon />}
        title={t("methodology.pilar.quality.title")}
        description={t("methodology.pilar.quality.description")}
      />
    </div>
  </BoundedSection>
);

const Teams = ({ data }) => (
  <BoundedSection
    className={style.Teams}
    boundedClassName={style.Teams_wrapper}
  >
    <div>
      <h3 className={style.Teams_title}>
        <GroupsTwoToneIcon />
        <Trans i18nKey="methodology.teams.title" />
      </h3>
      <div className={style.Teams_description}>
        <Trans i18nKey="methodology.teams.description" />
      </div>
    </div>
    <MorphingScrollImg img={data && getImage(data.teamsImg)} alt="" />
  </BoundedSection>
);

const TechLogo = ({ title, img, size, parallaxDistance }) => (
  <Parallax distance={parallaxDistance} className={`${style.TechLogo} ${size}`}>
    <img src={img} alt={title} className={style.TechLogo_img} />
  </Parallax>
);

const techLogos = [
  { title: "Java", size: style.xl, img: JavaLogo, parallaxDistance: -0.025 },
  {
    title: "Apache Kafka",
    size: style.s,
    img: KafkaLogo,
    parallaxDistance: 0.025,
  },
  { title: "React", size: style.l, img: ReactLogo, parallaxDistance: -0.025 },
  { title: "Html", size: style.xs, img: HtmlLogo, parallaxDistance: 0.025 },
  { title: "CSS", size: style.m, img: CssLogo, parallaxDistance: -0.025 },
  { title: "Rails", size: style.s, img: RailsLogo, parallaxDistance: 0.025 },
  {
    title: "Docker",
    size: style.xl,
    img: DockerLogo,
    parallaxDistance: -0.025,
  },
  { title: "Rest", size: style.xs, img: RestLogo, parallaxDistance: 0.025 },
  {
    title: "GraphQl",
    size: style.xl,
    img: GraphQlLogo,
    parallaxDistance: -0.025,
  },
  { title: "gRPC", size: style.m, img: GRPCLogo, parallaxDistance: 0.05 },
  {
    title: "JavaScript",
    size: style.xl,
    img: JavaScriptLogo,
    parallaxDistance: -0.025,
  },
  {
    title: "Cucumber",
    size: style.s,
    img: CucumberLogo,
    parallaxDistance: 0.025,
  },
  {
    title: "Cypress",
    size: style.xl,
    img: CypressLogo,
    parallaxDistance: -0.025,
  },
  {
    title: "Kubernetes",
    size: style.m,
    img: KubernetesLogo,
    parallaxDistance: 0.025,
  },
  {
    title: "MongoDB",
    size: style.l,
    img: MongoDbLogo,
    parallaxDistance: -0.025,
  },
  { title: "MySql", size: style.xs, img: MySqlLogo, parallaxDistance: 0.025 },
  {
    title: "PostgreSQL",
    size: style.xl,
    img: PostgreSQlLogo,
    parallaxDistance: -0.025,
  },
];

const TechLogos = () => (
  <BoundedSection
    className={style.TechLogos}
    boundedClassName={style.TechLogos_wrapper}
  >
    <NotDesktopView>
      <Swiper
        slidesPerView={3}
        spaceBetween={8}
        loopFillGroupWithBlank={true}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        allowTouchMove={false}
        speed={5000}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {techLogos.map(({ title, img, size, parallaxDistance }) => (
          <SwiperSlide key={uuid()}>
            <TechLogo
              title={title}
              img={img}
              size={size}
              parallaxDistance={parallaxDistance}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </NotDesktopView>
    <DesktopView>
      <Masonry
        breakpointCols={7}
        className={style.TechLogos_grid}
        columnClassName={style.TechLogos_gridCol}
      >
        {techLogos.map(({ title, img, size, parallaxDistance }) => (
          <TechLogo
            key={uuid()}
            title={title}
            img={img}
            size={size}
            parallaxDistance={parallaxDistance}
          />
        ))}
      </Masonry>
    </DesktopView>
  </BoundedSection>
);

const Tech = ({ t }) => (
  <>
    <BoundedSection>
      <h3 className={style.Tech_title}>
        <CodeTwoToneIcon />
        <Trans i18nKey="methodology.tech.title" />
      </h3>
    </BoundedSection>
    <TechLogos />
    <BoundedSection className={style.Tech}>
      <div className={style.Tech_description}>
        <p>{t("methodology.tech.description")}</p>
      </div>
    </BoundedSection>
  </>
);

const Phase = ({ number, title, icon, description }) => (
  <div className={style.Phase}>
    <div className={style.Phase_wrapper}>
      <motion.div
        className={style.Phase_circle}
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className={style.Phase_icon}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          {icon}
        </motion.div>
      </motion.div>
      <div className={style.Phase_text}>
        <h4 className={style.Phase_title}>
          <span className={style.Phase_number}>{number}</span>
          {title}
        </h4>
        <p className={style.Phase_description}>{description}</p>
      </div>
    </div>
  </div>
);

const Workflow = ({ t }) => (
  <BoundedSection>
    <motion.div
      className={style.Workflow}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <h3 className={style.Workflow_title}>
        {t("methodology.workflow.title")}
      </h3>
      <div className={style.Workflow_wrapper}>
        <Phase
          icon={<ForumTwoToneIcon />}
          number="1"
          title={t("methodology.workflow.phase.1.title")}
          description={t("methodology.workflow.phase.1.description")}
        />
        <Phase
          icon={<TipsAndUpdatesTwoToneIcon />}
          number="2"
          title={t("methodology.workflow.phase.2.title")}
          description={t("methodology.workflow.phase.2.description")}
        />
        <Phase
          icon={<SettingsTwoToneIcon />}
          number="3"
          title={t("methodology.workflow.phase.3.title")}
          description={t("methodology.workflow.phase.3.description")}
        />
        <Phase
          icon={<RocketLaunchTwoToneIcon />}
          number="4"
          title={t("methodology.workflow.phase.4.title")}
          description={t("methodology.workflow.phase.4.description")}
        />
        <Phase
          icon={<HandymanTwoToneIcon />}
          number="5"
          title={t("methodology.workflow.phase.5.title")}
          description={t("methodology.workflow.phase.5.description")}
        />
      </div>
    </motion.div>
  </BoundedSection>
);

const MethodolodyPage = ({ data }) => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("methodology.seo.title")}
      seoDescription={t("methodology.seo.description")}
    >
      <HeaderSection
        preTitle={t("methodology.header.preTitle")}
        title={t("methodology.header.title")}
        description={<p>{t("methodology.header.description")}</p>}
      />
      <Slider data={data} />
      <Pilars t={t} />
      <AwesomeQuote
        img={data && getImage(data.awesomeQuoteImg)}
        quote={<Trans i18nKey="methodology.quote" />}
      />
      <Teams data={data} />
      <Tech t={t} />
      <Workflow t={t} />
      <Cta
        title={t("methodology.cta.title")}
        description={t("methodology.cta.description")}
        btn={<RocketIconTwoTone />}
        btnLink="/contact"
      />
    </PageWrapper>
  );
};

export default MethodolodyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    slider1: file(relativePath: { eq: "misc/csuarez_call_gema.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    slider2: file(relativePath: { eq: "misc/mrama.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    teamsImg: file(relativePath: { eq: "misc/mmuino_ddorrego.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    awesomeQuoteImg: file(relativePath: { eq: "misc/falvarado_back.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;
